import * as Sentry from "@sentry/gatsby";

Sentry.init({
  dsn: process.env.GATSBY_SENTRY_DSN,
  environment: process.env.GATSBY_SENTRY_ENV,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0.006,
  integrations: [
    new Sentry.Replay({
      maskAllText: false,
      maskAllInputs: true,
    }),
  ],
});
